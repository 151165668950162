import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Carousel from 'react-bootstrap/Carousel';

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/mob-aa1.jpg"
          alt="First slide"
        />
      </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/new-banner.jpg"
          alt="First slide"
        />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/mob-aa2.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/new-banner2.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/mob-aa3.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/new-banner3.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/mob-aa4.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/new-banner4.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/mob-aa5.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/new-banner5.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/mob-aa6.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/new-banner6.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;