import { memo, useEffect, useState } from "react";
import twoetwoevent from "./TwoTwoinfo";
import LazyLoad from "react-lazy-load";
import { Twothreeinfo } from "./Twothreeinfo";
import axios from "axios";

function TwoFour() {
  const [number, setNumber] = useState(60);
  const [num3, setNum3] = useState(0);
  let setDis = "flex";
  const menu = [
    {
      src: "https://www.youtube.com/embed/4bSWVlrPnVE",
      info: "Short Video Of Bangalore Stroke Nursing Conclave 2022 Final",
    },
    {
      src: "https://www.youtube.com/embed/eCuJ3jo6t0w",
      info: "Bangalore Stroke Nursing Conclave 2022 Final Video",
    },
  ];
  let changer = {
    display: `${setDis}`,
  };
  function callme() {
    setDis = "none";
    changer = {
      display: `${setDis}`,
    };
  }
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo29.appaddindia.net/get-images.php?sort=aa1"
        );
        setImages(response.data); // Assuming response.data contains the array of image URLs
        setLoading(false);
      } catch (err) {
        console.log(err)
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  return (
    <>
      <div className="sectionbott mb-0 pb-4 pt-5">
        <div className="container">
          {/* <div className="vedioscrolla mb-5">
           <div className="row cetifier evenpm-2">
               <h3>WORLD STROKE DAY 2022 VIDEO</h3>
           </div>
                    <div className="row ">
                            {
                                menu.map((elem)=>{
                                    const {src,info}=elem;
                                    return (<>
                                    <div className="col-md-6 col-12 pade pt-3">
                                        <div className="vediooter">
                                            <div className="imgve"></div>
                                            <iframe className="imgboxevent vedieosize" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                           
                                        </div>
                                        <h5 className="mt-3">{info}</h5>
                                    </div>
                                    </>)
                                })
                            }
                        </div>
                    </div> */}
          <div className="row cetifier evenpm-2">
            <h3>WORLD STROKE DAY 2024</h3>
          </div>
          <div className="row">
            <div className="col-lg-12 conevent">
              <h5>
              The Bangalore Stroke Support Group (BSSG) hosted its World Stroke Day 2024 event under the theme “Together #BeGreaterThanStroke,” attended by over 250 participants. Presided over by Dr. B.N. Gangadhar and other eminent guests, the program highlighted the message “Time is Brain” through expert talks, a street play, and a musical performance by the AVNEE group. The Karnataka Stroke Foundation, led by Dr. G.T. Subhas, organized a Lalbagh exhibition on stroke prevention and treatment, fostering public engagement. BSSG continues to empower stroke survivors and caregivers through regular meetings and comprehensive support initiatives.
              </h5>
            </div>
          </div>
          <div className="row">
            {images.map((elem, num) => {
              const { img } = elem;
              if ( number > num) {
                {
                  return (
                    <>
                      <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                        <img src={`https://demo29.appaddindia.net/${elem.url}`} alt="" />
                      </LazyLoad>
                    </>
                  );
                }
              }
            })}
          </div>
          {
            images.length>=number && (
                <div
            style={changer}
            onClick={() => {
              setNumber(number + 60);
            }}
            className="loadmore pt-4"
          >
            <button className="loadbut">
              <h6>Load More</h6>
            </button>
          </div>
            )
          }
        </div>
      </div>
    </>
  );
}
export default memo(TwoFour);
