import ActivityInfo from "./ActivityInfo";
import Eventdisplayer from "./Evendisplayer";
import FirstDivider from "./Firstdivider";
import HomeInfoSlider from "./HomeInfoSlider";
import HomeMap from "./HomeMap";
import Homegallery19 from "./Homegallery19";
import Homegallery21 from "./Homegallery21";
import HomeGallery22 from "./Homegallery22";
import Homegallery23 from "./Homegallery23";
import Homegallery24 from "./Homegallery24";
import Hospitalinfo from "./Hospitalinfo";
import Mobilemenu from "./Mobilemenu";
import OfficeBear from "./OfficeBear";
import OfficeMember from "./OfficeMember";
import PastOffice from "./PastOffice";
import UncontrolledExample from "./Slider";
import Welcomebox from "./Welcomebox";

function Home() {
  return (
    <>
      <UncontrolledExample />
      <Hospitalinfo />
      <OfficeBear />
      <Welcomebox />
      <PastOffice />
      <OfficeMember />
      <FirstDivider />
      <Eventdisplayer />
      <Homegallery24/>
      <Homegallery23 />
      <HomeGallery22 />
      <Homegallery21 />
      <Homegallery19 />
      <HomeInfoSlider />
      <Welcomebox />
      <ActivityInfo />
      <HomeMap />
    </>
  );
}
export default Home;
