export const Twothreeinfo = [
  {
    img: "../photo/im23001.jpg",
  },
  {
    img: "../photo/im23001.jpg",
  },
  {
    img: "../photo/im23002.jpg",
  },
  {
    img: "../photo/im23003.jpg",
  },
  {
    img: "../photo/im23004.jpg",
  },
  {
    img: "../photo/im23005.jpg",
  },
  {
    img: "../photo/im23006.jpg",
  },
  {
    img: "../photo/im23007.jpg",
  },
  {
    img: "../photo/im23008.jpg",
  },
  {
    img: "../photo/im23009.jpg",
  },
  {
    img: "../photo/im23010.jpg",
  },
  {
    img: "../photo/im23011.jpg",
  },
  {
    img: "../photo/im23012.jpg",
  },
  {
    img: "../photo/im23013.jpg",
  },
  {
    "img": "../wetransfer/aa1.jpg"
  },
  
  {
    "img": "../wetransfer/aa2.jpg"
  },
  {
    "img": "../wetransfer/aa3.jpg"
  },
  {
    "img": "../wetransfer/aa4.jpg"
  },
  {
    "img": "../wetransfer/aa5.jpg"
  },
  {
    "img": "../wetransfer/aa6.jpg"
  },
  {
    "img": "../wetransfer/aa7.jpg"
  },
  {
    "img": "../wetransfer/aa8.jpg"
  },
  {
    "img": "../wetransfer/aa9.jpg"
  },
  {
    "img": "../wetransfer/aa10.jpg"
  },
  {
    "img": "../wetransfer/aa11.jpg"
  },
  {
    "img": "../wetransfer/aa12.jpg"
  },
  {
    "img": "../wetransfer/aa13.jpg"
  },
  {
    "img": "../wetransfer/aa14.jpg"
  },
  {
    "img": "../wetransfer/aa15.jpg"
  },
  {
    "img": "../wetransfer/aa16.jpg"
  },
  
  {
    "img": "../wetransfer/aa17.jpg"
  },
  {
    "img": "../wetransfer/aa18.jpg"
  },
  {
    "img": "../wetransfer/aa19.jpg"
  },
  {
    "img": "../wetransfer/aa20.jpg"
  },
  {
    "img": "../wetransfer/aa21.jpg"
  },
  {
    "img": "../wetransfer/aa22.jpg"
  },
  {
    "img": "../wetransfer/aa23.jpg"
  },
  {
    "img": "../wetransfer/aa24.jpg"
  },
  {
    "img": "../wetransfer/aa25.jpg"
  },
  {
    "img": "../wetransfer/aa26.jpg"
  },
  {
    "img": "../wetransfer/aa27.jpg"
  },
  {
    "img": "../wetransfer/aa28.jpg"
  },
  {
    "img": "../wetransfer/aa29.jpg"
  },
  {
    "img": "../wetransfer/aa30.jpg"
  },
  {
    "img": "../wetransfer/aa31.jpg"
  },
  {
    "img": "../wetransfer/aa32.jpg"
  },
  {
    "img": "../wetransfer/aa33.jpg"
  },
  {
    "img": "../wetransfer/aa34.jpg"
  },
  {
    "img": "../wetransfer/aa35.jpg"
  },
  {
    "img": "../wetransfer/aa36.jpg"
  },
  {
    "img": "../wetransfer/aa37.jpg"
  },
  {
    "img": "../wetransfer/aa38.jpg"
  },
  {
    "img": "../wetransfer/aa39.jpg"
  },
  {
    "img": "../wetransfer/aa40.jpg"
  },
  {
    "img": "../wetransfer/aa41.jpg"
  },
  {
    "img": "../wetransfer/aa42.jpg"
  },
  {
    "img": "../wetransfer/aa43.jpg"
  },
  {
    "img": "../wetransfer/aa44.jpg"
  },
  {
    "img": "../wetransfer/aa45.jpg"
  },
  {
    "img": "../wetransfer/aa46.jpg"
  },
  {
    "img": "../wetransfer/aa47.jpg"
  },
  {
    "img": "../wetransfer/aa48.jpg"
  },
  {
    "img": "../wetransfer/aa49.jpg"
  },
  {
    "img": "../wetransfer/aa50.jpg"
  },
  {
    "img": "../wetransfer/aa51.jpg"
  },
  {
    "img": "../wetransfer/aa52.jpg"
  },
  {
    "img": "../wetransfer/aa53.jpg"
  },
  {
    "img": "../wetransfer/aa54.jpg"
  },
  {
    "img": "../wetransfer/aa55.jpg"
  },
  {
    "img": "../wetransfer/aa56.jpg"
  },
  {
    "img": "../wetransfer/aa57.jpg"
  },
  {
    "img": "../wetransfer/aa58.jpg"
  },
  {
    "img": "../wetransfer/aa59.jpg"
  },
  {
    "img": "../wetransfer/aa60.jpg"
  },
  {
    "img": "../wetransfer/aa61.jpg"
  },
  {
    "img": "../wetransfer/aa62.jpg"
  },
  {
    "img": "../wetransfer/aa63.jpg"
  },
  {
    "img": "../wetransfer/aa64.jpg"
  },
  {
    "img": "../wetransfer/aa65.jpg"
  },
  {
    "img": "../wetransfer/aa66.jpg"
  },
  {
    "img": "../wetransfer/aa67.jpg"
  },
  {
    "img": "../wetransfer/aa68.jpg"
  },
  {
    "img": "../wetransfer/aa69.jpg"
  },
  {
    "img": "../wetransfer/aa70.jpg"
  },
  {
    "img": "../wetransfer/aa71.jpg"
  },
  {
    "img": "../wetransfer/aa72.jpg"
  },
  {
    "img": "../wetransfer/aa73.jpg"
  },
  {
    "img": "../wetransfer/aa74.jpg"
  },
  {
    "img": "../wetransfer/aa75.jpg"
  },
  {
    "img": "../wetransfer/aa76.jpg"
  },
  {
    "img": "../wetransfer/aa77.jpg"
  },
  {
    "img": "../wetransfer/aa78.jpg"
  },
  {
    "img": "../wetransfer/aa79.jpg"
  },
  {
    "img": "../wetransfer/aa80.jpg"
  },
  {
    "img": "../wetransfer/aa81.jpg"
  },
  {
    "img": "../wetransfer/aa82.jpg"
  },
  {
    "img": "../wetransfer/aa83.jpg"
  },
  {
    "img": "../wetransfer/aa84.jpg"
  },
  {
    "img": "../wetransfer/aa85.jpg"
  },
  {
    "img": "../wetransfer/aa86.jpg"
  },
  {
    "img": "../wetransfer/aa87.jpg"
  },
  {
    "img": "../wetransfer/aa88.jpg"
  },
  {
    "img": "../wetransfer/aa89.jpg"
  },
  {
    "img": "../wetransfer/aa90.jpg"
  },
  {
    "img": "../wetransfer/aa91.jpg"
  },
  {
    "img": "../wetransfer/aa92.jpg"
  },
  {
    "img": "../wetransfer/aa93.jpg"
  },
  {
    "img": "../wetransfer/aa94.jpg"
  },
  {
    "img": "../wetransfer/aa95.jpg"
  },
  {
    "img": "../wetransfer/aa96.jpg"
  },
  {
    "img": "../wetransfer/aa97.jpg"
  },
  {
    "img": "../wetransfer/aa98.jpg"
  },
  {
    "img": "../wetransfer/aa99.jpg"
  },
  {
    "img": "../wetransfer/aa100.jpg"
  },
  {
    "img": "../wetransfer/aa101.jpg"
  },
  {
    "img": "../wetransfer/aa102.jpg"
  },
  {
    "img": "../wetransfer/aa103.jpg"
  },
  {
    "img": "../wetransfer/aa104.jpg"
  },
  {
    "img": "../wetransfer/aa105.jpg"
  },
  {
    "img": "../wetransfer/aa106.jpg"
  },
  {
    "img": "../wetransfer/aa107.jpg"
  },
  {
    "img": "../wetransfer/aa108.jpg"
  },
  {
    "img": "../wetransfer/aa109.jpg"
  },
  {
    "img": "../wetransfer/aa110.jpg"
  },
  {
    "img": "../wetransfer/aa111.jpg"
  },
  {
    "img": "../wetransfer/aa112.jpg"
  },
  {
    "img": "../wetransfer/aa113.jpg"
  },
  {
    "img": "../wetransfer/aa114.jpg"
  },
  {
    "img": "../wetransfer/aa115.jpg"
  },
  {
    "img": "../wetransfer/aa116.jpg"
  },
];
