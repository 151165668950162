import logo from "./logo.svg";
import "./App.css";
import Header from "./component/Header";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import FixHeader from "./component/FixHeader";
import Footer from "./component/Footer";
import Contact from "./component/Contact/Contact";
import About from "./component/About/About";
import Event from "./component/Event/Event";
import OfficeBear from "./component/OfficeBear";
import PastOffice from "./component/PastOffice";
import OfficeBearpage from "./component/Officebear/OfficeBearpage";
import Activity from "./component/Activity/Activity";
import Mobilemenu from "./component/Mobilemenu";
import Menu from "./component/Menu";
import Twotwo from "./component/Event/Twotwo";
import Twoone from "./component/Event/Twoone";
import Onenine from "./component/Event/Onenine";
import Twothree from "./component/Event/Twothree";
import Twofour from "./component/Event/Twofour";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Menu />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route path="/activity" element={<Activity />}></Route>
          <Route path="/event" element={<Event />}>
            <Route path="/event/twotwo" element={<Twotwo />}></Route>
            <Route path="/event/twoone" element={<Twoone />}></Route>
            <Route path="/event/onenine" element={<Onenine />}></Route>
            <Route path="/event/twothree" element={<Twothree />}></Route>
            <Route path="/event/twofour" element={<Twofour />}></Route>
          </Route>
          <Route path="/office" element={<OfficeBearpage />}>
            <Route path="/office/present" element={<OfficeBear />}></Route>
            <Route path="/office/past" element={<PastOffice />}></Route>
          </Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
