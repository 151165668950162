import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useState } from "react";
import LazyLoad from 'react-lazy-load';
function Eventdisplayer(){
    const [index,setIndex]=useState(0);
    const [bodyoneone, setBodyoneone]=useState(firstone);
    const [bodytwotwo, setBodytwotwo]=useState(firsttwo);
    const [bodythreethree, setBodythreethree]=useState(firstthree);
    const [bodyfourfour, setBodyfourfour]=useState(firstfour);
    const [bodyfivefive, setBodyfivefive]=useState(firstfive);
    const [bodysixsix, setBodysixsix]=useState(firstSix);
    const [righttrue, setRighttrue]=useState(true);
    useEffect(()=>{
        let settimer=setInterval(()=>{
            if(righttrue){
                if(index<9){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else{
                clearInterval(settimer)
            }
        },2000)
        return ()=>{
            clearInterval(settimer);
        }
    })
    const transfer={
        transform:`translateX(-${index*10}%)`
       }
    function rightindex(){
        setRighttrue(false)
        if(index<9){
            setIndex(index+1)
        }else{
            setIndex(0)
        }
    }
    function leftindex(){
        setRighttrue(false)
        if(index>-1){
            setIndex(index-1);
        }else{
            setIndex(9);
        }
    }
    function sliderstop(){
        setRighttrue(false)
    }
    return (
        <>
        <section onClick={sliderstop} className="section pt-5 pb-5">
            <div className="container">
                <div className="row cetifier">
                    <h1>BANGALORE STROKE SUPPORT GROUP</h1>
                    <h3 className="orange">Organisational Activity</h3>
                </div>
            </div>
            <div className="container">
                <div className="tittle">

                </div>
                <div className="headeroffbox">
                <div className="hiderofficeover">
                <div className="headerofficeevent" style={transfer}>
                {bodysixsix}
                    {bodyoneone}
                    {bodytwotwo}
                    {bodyfourfour}
                    {bodyfivefive}
                    {bodythreethree}
                    {bodyoneone}
                    {bodytwotwo}
                    {bodyfourfour}
                    {bodyfivefive}
                </div>
                </div>
                <button onClick={rightindex} className="rightbutton"><img src="photo/right-arrow (2).png" alt="" /></button>
                <button onClick={leftindex} className="leftbutton"><img src="photo/left-arrow.png" alt="" /></button>
            </div>
            </div>
        </section>
        </>
    )
}
export default Eventdisplayer;

const firstone=()=>{
    return (
            <div className="eventbox">
                <LazyLoad className="imgeven">
                    <img src="photo/eventa.jpg" alt="" />
                </LazyLoad>
                <div className="texteventone">
                    <div className="dispadder">
                        <div className="imgdisplayer">
                            <div className="oneimg">
                                <img src="photo/logo.png" alt="" />
                            </div>
                            <div className="twoimg">
                                <img src="photo/logo-text.png" alt="" />
                            </div>
                        </div>
                        <div className="buttoncenter"><div><h6 className="buttoneven">ACTIVITY</h6></div></div>
                    </div>
                    <div className="textofeven">
                        <div><h3>Grand Launch for Bangalore Stroke Support Group</h3></div>
                        <p>The program on 20th March is as follows… 10am to 11 am —— Diet in Stroke by Mrs Emmany, Clinical Dietician 11 am to 12 noon — Fun based activity in improving balance for stroke survivors by Mr. Venkatesh, Neuro physical therapist.</p>
                    </div>
                    <div className="">
                        <div>
                            <h6 className="buttonevenmang">Gallery</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const firsttwo=()=>{
    return (
            <div className="eventbox">
                <LazyLoad className="imgeven">
                    <img src="photo/eventb.jpg" alt="" />
                </LazyLoad>
                <div className="texteventone">
                    <div className="dispadder">
                        <div className="imgdisplayer">
                            <div className="oneimg">
                                <img src="photo/logo.png" alt="" />
                            </div>
                            <div className="twoimg">
                                <img src="photo/logo-text.png" alt="" />
                            </div>
                        </div>
                        <div className="buttoncenter"><div><h6 className="buttoneven">WORLD STROKE DAY</h6></div></div>
                    </div>
                    <div className="textofeven">
                        <div><h3>World Stroke Day 2022</h3></div>
                        <p>On the occasion of World Stroke Day, Bangalore Stroke Support Group and Karnataka Stroke Foundation is organising Karnataka Stroke Nursing Conclave 2022- A stroke program for the nurses on 22 nd Oct at BMC main auditorium, Bangalore Medical College.</p>
                    </div>
                    <div className="">
                        <div>
                            <h6 className="buttonevenmang">Gallery</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const firstthree=()=>{
    return (
            <div className="eventbox">
                <LazyLoad className="imgeven">
                    <img src="photo/evenntd.jpg" alt="" />
                </LazyLoad>
                <div className="texteventone">
                    <div className="dispadder">
                        <div className="imgdisplayer">
                            <div className="oneimg">
                                <img src="photo/logo.png" alt="" />
                            </div>
                            <div className="twoimg">
                                <img src="photo/logo-text.png" alt="" />
                            </div>
                        </div>
                        <div className="buttoncenter"><div><h6 className="buttoneven">ACTIVITY</h6></div></div>
                    </div>
                    <div className="textofeven">
                        <div><h3>Fun based activity in improving balance for stroke survivors</h3></div>
                        <p>BPSS is being set up with an aim to bring all the stakeholders i.e stroke specialists, physicians, rehabilitation specialists, healthcare professionals, hospital administrators, caregivers, stroke survivors, and public at large on a single platform to interact, share, discuss issues related to stroke.</p>
                    </div>
                    <div className="">
                        <div>
                            <h6 className="buttonevenmang">Gallery</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const firstfour=()=>{
    return (
            <div className="eventbox">
                <LazyLoad className="imgeven">
                    <img src="photo/eventc.jpg" alt="" />
                </LazyLoad>
                <div className="texteventone">
                    <div className="dispadder">
                        <div className="imgdisplayer">
                            <div className="oneimg">
                                <img src="photo/logo.png" alt="" />
                            </div>
                            <div className="twoimg">
                                <img src="photo/logo-text.png" alt="" />
                            </div>
                        </div>
                        <div className="buttoncenter"><div><h6 className="buttoneven">WORLD STROKE DAY</h6></div></div>
                    </div>
                    <div className="textofeven">
                        <div><h3>World Stroke Day 2021</h3></div>
                        <p>BSSG organized World Stroke Day 2021 public event on Saturday 23rd, a walkathon followed by formal function at BMC Alumni auditorium, Bangalore. Chief guests, guest of honour, BSSG President & Secretary and ISA President and Secretary will share their message.</p>
                    </div>
                    <div className="">
                        <div>
                            <h6 className="buttonevenmang">Gallery</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const firstfive=()=>{
    return (
            <div className="eventbox">
                <LazyLoad className="imgeven">
                    <img src="photo/a19.jpg" alt="" />
                </LazyLoad>
                <div className="texteventone">
                    <div className="dispadder">
                        <div className="imgdisplayer">
                            <div className="oneimg">
                                <img src="photo/logo.png" alt="" />
                            </div>
                            <div className="twoimg">
                                <img src="photo/logo-text.png" alt="" />
                            </div>
                        </div>
                        <div className="buttoncenter"><div><h6 className="buttoneven">WORLD STROKE DAY</h6></div></div>
                    </div>
                    <div className="textofeven">
                        <div><h3>World Stroke Day 2019</h3></div>
                        <p>Event conducted on the occasion of world stroke day on Oct 20 2019 at BMC Alumni auditorium. Conduct campaigns to give information about the signs and symptoms of stroke, the importance of seeking immediate medical attention, and the resources available to stroke survivors.</p>
                    </div>
                    <div className="">
                        <div>
                            <h6 className="buttonevenmang">Gallery</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const firstSix=()=>{
    return (
            <div className="eventbox">
                <LazyLoad className="imgeven">
                    <img src="photo/675be6ef621f1.jpg" alt="" />
                </LazyLoad>
                <div className="texteventone">
                    <div className="dispadder">
                        <div className="imgdisplayer">
                            <div className="oneimg">
                                <img src="photo/logo.png" alt="" />
                            </div>
                            <div className="twoimg">
                                <img src="photo/logo-text.png" alt="" />
                            </div>
                        </div>
                        <div className="buttoncenter"><div><h6 className="buttoneven">WORLD STROKE DAY</h6></div></div>
                    </div>
                    <div className="textofeven">
                        <div><h3>World Stroke Day 2024</h3></div>
                        <p>BSSG's World Stroke Day 2024, themed “Together #BeGreaterThanStroke,” highlighted “Time is Brain” through expert talks, a street play, musical performances, and a Lalbagh exhibition by Karnataka Stroke Foundation, fostering public awareness and empowering stroke survivors and caregivers.</p>
                    </div>
                    <div className="">
                        <div>
                            <h6 className="buttonevenmang">Gallery</h6>
                        </div>
                    </div>
                </div>
            </div>
    )
}