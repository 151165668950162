import { memo, useState } from "react";
import { Link } from "react-router-dom";
function Eventselect() {
  const [first, setFirst] = useState("white");
  const [second, setSecond] = useState("black");
  const [three, setThree] = useState("#ff6d12");
  const [four, setFour] = useState("white");
  const [five, setFive] = useState("white");
  const [six, setSix] = useState("black");
  const [select, setSelect] = useState(0);

  const menu = [
    {
      link: "/event/twofour",
      name: "World Stroke Day 2024",
    },
    {
      link: "/event/twothree",
      name: "World Stroke Day 2023",
    },
    {
      link: "/event/twotwo",
      name: "World Stroke Day 2022",
    },
    {
      link: "/event/twoone",
      name: "World Stroke Day 2021",
    },
    {
      link: "/event/onenine",
      name: "World Stroke Day 2019",
    },
  ];

  return (
    <>
      <section className="mt-4 eventselect">
        <div className="container">
          <div className="row">
            {menu.map((elem, i) => {
              return (
                <>
                  <Link
                    to={elem.link}
                    onClick={() => {
                      setSelect(i);
                    }}
                    style={{ textDecoration: "none" }}
                    className="col-md-4 "
                  >
                    <div
                      style={{
                        backgroundColor: i == select ? "#ff6d12" : "white",
                        color: i == select ? "white" : "black",
                      }}
                      className="col-12 selectoffice"
                    >
                      <h5>{elem.name}</h5>
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Eventselect);
