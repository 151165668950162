import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useRef, useState } from "react"
import LazyLoad from "react-lazy-load";
import { memo } from "react";
import { Link } from "react-router-dom";
import Homeinfo19v from "./Homeinfo19v";
import Onenineinfo from "./Event/Onenineinfo";
import axios from "axios";
function HomeGallery24(){
    const [number, setNumber]=useState()
    const [index,setIndex]=useState(2)
    const [cycle, setCycle]=useState(true)
    const myref=useRef();
function right(){
    setCycle(false)
    if(window.innerWidth>1200){
        setNumber(25)
        if(index<Onenineinfo.length-1){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }else if(window.innerWidth>992){
        setNumber(33.3333)
        if(index<Onenineinfo.length){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }else if(window.innerWidth>768){
        setNumber(50)
        if(index<Onenineinfo.length-1){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }else{
        setNumber(100)
        if(index<Onenineinfo.length-2){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }
}

function left(){
    setCycle(false)
    if(window.innerWidth>1200){
        setNumber(25)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(Onenineinfo.length-1)
        }
    }else if(window.innerWidth>992){
        setNumber(33.3333)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(Onenineinfo.length)
        }
    }else if(window.innerWidth>768){
        setNumber(50)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(Onenineinfo.length+1)
        }
    }else{
        setNumber(100)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(Onenineinfo.length+2)
        }
    }
}

function stop(){
    setCycle(false)
}

const [men,setmen]=useState(Onenineinfo)
useEffect(()=>{
    const timer=setInterval(()=>{
        if(cycle==true){
            if(window.innerWidth>1200){
                setNumber(25)
                if(index<Onenineinfo.length-1){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else if(window.innerWidth>992){
                setNumber(33.3333)
                if(index<Onenineinfo.length){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else if(window.innerWidth>768){
                setNumber(50)
                if(index<Onenineinfo.length+1){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else{
                setNumber(100)
                if(index<Onenineinfo.length+2){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }
        }else{
            clearInterval(timer);
        }
    },2000)
    return (()=>{
        clearInterval(timer)
    })
})

    const changer={
        transform:`translateX(-${index*number}%)`
    }
// const myref=useRef();

// function scroller(){
//     setGallbox([...gallbox, ...Onenineinfo])
// }
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo29.appaddindia.net/get-images.php?sort=aa1"
        );
        setImages(response.data); // Assuming response.data contains the array of image URLs
        setLoading(false);
      } catch (err) {
        console.log(err)
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;


    return (<>
             <div onClick={stop} className="sectionbott mb-0 pb-5">
            <div className="container">
                <div className="row cetifier evenpm-2">
                    <h1>WORLD STROKE DAY 2024</h1>
                </div>
                <div className="row">
                    <div className="col-lg-12 conevent">
                    <h5>The Bangalore Stroke Support Group (BSSG) hosted its World Stroke Day 2024 event under the theme “Together #BeGreaterThanStroke,” attended by over 250 participants. Presided over by Dr. B.N. Gangadhar and other eminent guests, the program highlighted the message “Time is Brain” through expert talks, a street play, and a musical performance by the AVNEE group. The Karnataka Stroke Foundation, led by Dr. G.T. Subhas, organized a Lalbagh exhibition on stroke prevention and treatment, fostering public engagement. BSSG continues to empower stroke survivors and caregivers through regular meetings and comprehensive support initiatives.</h5>
                    </div>
                </div>
                <div className="imagetagho"><h6>Image Gallery</h6></div>
                {/* <div ref={myref} onScroll={scroller} className="disgaller"> */}
                <div className="rowgaleven">
                <div ref={myref} style={changer} className="disgaller disgallerydis">
                        {
                            images.map((elem)=>{
                                const {img}=elem;
                                return (<>
                                <LazyLoad className="col-xl-3 col-lg-4 col-md-6 col-12 p-1">
                                    <div className="imgboxevent">
                                        <img className="imgboxev" src={`https://demo29.appaddindia.net/${elem.url}`} alt="" />
                                        {/* <img className="imgplus" src="photo/plus.png" alt="" /> */}
                                    </div>
                                </LazyLoad>
                                </>)
                            })
                        }
                        {
                            Onenineinfo.map((elem, num)=>{
                                const {img}=elem;
                                if(num<3){
                                    return (<>
                                        <LazyLoad className="col-xl-3 col-lg-4 col-md-6 col-12 p-1">
                                    <div className="imgboxevent">
                                        <img className="imgboxev" src={img} alt="" />
                                        <img className="imgplus" src="photo/plus.png" alt="" />
                                    </div>
                                </LazyLoad>
                                        </>)
                                }
                            })
                        }
                    </div>
            <button onClick={right} className="leftbuttona"><img src="photo/left-arrow.png" alt="" /></button>
            <button onClick={left} className="rightbuttona"><img src="photo/right-arrow (2).png" alt="" /></button> 
                </div>
                <div className="eventbut">
                    <Link to="/event/onenine" className="gallhomebu"><h5>Gallery</h5></Link>
                </div>
            </div>
        </div>
            </>)
}
export default memo(HomeGallery24);