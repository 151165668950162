import { memo } from "react"
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function Footer(){
    return (<>
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <div className="footimgbox">
                        <div className="footinnerlogo">
                            <LazyLoad className="footlogo">
                                <img src="../photo/logo.png" alt="" />
                            </LazyLoad>
                            <LazyLoad className="footloco">
                                <img src="../photo/logotext2.png" alt="" />
                            </LazyLoad>
                        </div>
                    </div>
                    <div className="footinfocon">
                        <h6>Bangalore Stroke Support Group is an initiative by association of club of service oriented doctors and stroke victims.</h6>
                    </div>
                    <div className="footinfoic">
                    <LazyLoad>
                        <ul className="iconbox">
                            <li><a href="https://www.facebook.com/people/Bangalore-Stroke-Support-Group-%E0%B2%AC%E0%B3%86%E0%B2%82%E0%B2%97%E0%B2%B3%E0%B3%82%E0%B2%B0%E0%B3%81-%E0%B2%AA%E0%B2%BE%E0%B2%B0%E0%B3%8D%E0%B2%B6%E0%B3%8D%E0%B2%B5%E0%B2%B5%E0%B2%BE%E0%B2%AF%E0%B3%81-%E0%B2%B8%E0%B3%86%E0%B3%95%E0%B2%B5%E0%B2%BE-%E0%B2%B8%E0%B2%82%E0%B2%98/100072135099512/"><img src="../photo/facebook (3).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/instagram (2).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/twitter (3).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/google.png" alt="" /></a></li>
                        </ul>
                    </LazyLoad>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="secexplotitt">
                        <h3>Explore</h3>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <Link to="/" className="foseli"><h6>Home</h6></Link>
                            </li>
                            <li>
                                <Link to="/about" className="foseli"><h6>About Us</h6></Link>
                            </li>
                            <li>
                                <Link to="/activity" className="foseli"><h6>Activity</h6></Link>
                            </li>
                            <li>
                                <Link to="/event/twofour" className="foseli"><h6>Events</h6></Link>
                            </li>
                            <li>
                                <Link to="/office/present" className="foseli"><h6>Office Bearers</h6></Link>
                            </li><li>
                                <Link to="/contact" className="foseli"><h6>Contact Us</h6></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="secexplotitt">
                        <h3>Contact</h3>
                    </div>
                    <div>
                        <div className="fothin">
                            <div className="fothinp">
                                <LazyLoad className="fotthph">
                                    <img src="../photo/phone-receiver-silhouette.png" alt="" />
                                </LazyLoad>
                                <div className="forthphn">
                                    <h6>9901066260</h6>
                                </div>
                            </div>
                        </div>
                        <div className="fothin">
                            <div className="fothinp">
                                <LazyLoad className="fotthph">
                                    <img src="../photo/email (1).png" alt="" />
                                </LazyLoad>
                                <div className="forthphn">
                                    <h6>muralidharankv@gmail.com</h6>
                                </div>
                            </div>
                        </div>
                        <div className="fothin">
                            <div className="fothinp">
                                <LazyLoad className="fotthph">
                                    <img src="../photo/maps-and-flags.png" alt="" />
                                </LazyLoad>
                                <div className="forthphn">
                                    <h6>Bangalore Parshvavayu Seva Sangha F-003, Nagarjuna Green Ridge Apartment, 19th Main Road, 27th Cross, 80 Feet Road 2nd Phase H.S.R Layout, Bangalore-560102</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="secexplotitt">
                        <h3>Follow Us On</h3>
                    </div>
                    <div className="fb-page" style={{width:"100%"}} data-href="https://www.facebook.com/profile.php?id=100072135099512" data-tabs="timeline" data-height="260" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/profile.php?id=100072135099512" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/profile.php?id=100072135099512">Bangalore Stroke Support Group  - ಬೆಂಗಳೂರು ಪಾರ್ಶ್ವವಾಯು ಸೇವಾ ಸಂಘ</a></blockquote></div>
                </div>
            </div>
        </div>
    </footer>
    </>)
}
export default memo(Footer);